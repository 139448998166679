import { detailTypes } from "../../ActionTypes/detailTypes";
import { DetailActions, DetailsState } from "../../types/type";

const initialState: DetailsState = {
  pending: false,
  detail: [],
  error: null,
};

export default (state = initialState, action: DetailActions,) => {
  switch (action.type) {
    case detailTypes.FETCH_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
    case detailTypes.FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        detail: action.payload.detail,
        error: null
      };
    case detailTypes.FETCH_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        list: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
