import React, { useEffect } from "react";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { Button, Col, Row } from 'react-bootstrap';
import './news.css';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redax/reducers/rootReducer';
import { fetchPromotionRequest } from '../../redax/actions/promotionActions/promotionActions';

function News() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pending, list } = useSelector(
    (state: RootState) => state.promotion
  );

  useEffect(() => {
    dispatch(fetchPromotionRequest());
  }, [dispatch]);

  const loadPromotion = (id: any) => {
    history.push('/promotion/read/' + id);
  }

  return (
    <div className='section container-fluid'>
      {/* <h3 className="main-header-txt">ШИНЭ УРАМШУУЛАЛ</h3> */}
      <Row className='p-0'>
        <Col md={9} className='m-0 p-0 pb-3'>
          <Row className='p-0'>
            {list.map((list: any, ind: Number) => {
              return (
                <Col xl={4} sm={6} xs={12} className='p-0' key={list.id} onClick={() => loadPromotion(list.id)}>
                  <Card className='p-md-3 image-card'>
                    <Card.Img variant="top" src={"./images/news/" + list.image} />
                    <Card.Body className='body-card'>
                      <div>
                        <Card.Title>{list.name}</Card.Title>
                        <Card.Text>
                          {list.short_desc}<br/>
                          {list.n_date}
                        </Card.Text>
                      </div>
                      {/* <Link to={`/promotion/read/${list.id}`}>Дэлгэрэнгүй</Link> */}
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
            
            {/* <Col xl={4} sm={6} xs={12} className=' p-0'>
              <Card  className='p-md-3'>
                <Card.Img variant="top" src="images/s2.jpg" />
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This card has supporting text below as a natural lead-in to
                    additional content.{' '}
                  </Card.Text>
                  <Link to="/news/read/1">More</Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} sm={6} xs={12} className=' p-0'>
              <Card  className='p-md-3'>
                <Card.Img variant="top" src="images/s3.png" />
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This is a wider card with supporting text below as a natural lead-in
                    to additional content. This card has even longer content than the
                    first to show that equal height action.
                  </Card.Text>
                  <Link to="/news/read/1">More</Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} sm={6} xs={12} className=' p-0'>
              <Card  className='p-md-3'>
                <Card.Img variant="top" src="images/s1.jpg" />
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This is a wider card with supporting text below as a natural lead-in
                    to additional content. This content is a little bit longer.
                  </Card.Text>
                  <Link to="/news/read/1">More</Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} sm={6} xs={12} className=' p-0'>
              <Card  className='p-md-3'>
                <Card.Img variant="top" src="images/s2.jpg" />
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This card has supporting text below as a natural lead-in to
                    additional content.{' '}
                  </Card.Text>
                  <Link to="/news/read/1">More</Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} sm={6} xs={12} className=' p-0'>
              <Card  className='p-md-3'>
                <Card.Img variant="top" src="images/s3.png" />
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This is a wider card with supporting text below as a natural lead-in
                    to additional content. This card has even longer content than the
                    first to show that equal height action.
                  </Card.Text>
                  <Link to="/news/read/1">More</Link>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Col>
        <Col md={3} className='m-0 p-0 pb-3 fb-padd'>
        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmglpages%2F&tabs=timeline&width=330&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=361420201045179" width="100%" height="800" scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title="FB"></iframe>
        </Col>
      </Row>
    </div>
  );
}

export default News;