import { combineReducers } from "redux";
import postReducer from "./postsReduser/postReduser";
import listReduser from "./listReduser/listReduser";
import detailReduser from "./detailReduser/detailReduser";
import promotionReduser from "./promotionReduser/promotionReduser";
import promotionDetailReduser from "./promotionDetailReduser/promotionDetailReduser";
import searchReduser from "./searchReduser/searchReduser";
import rateReduser from "./rateReduser/rateReduser";

const rootReducer: any = combineReducers({
  posts: postReducer,
  list: listReduser,
  detail: detailReduser,
  promotion: promotionReduser,
  promotionDetail: promotionDetailReduser,
  search: searchReduser,
  rate: rateReduser
});

export type RootState  = ReturnType<typeof rootReducer>;

export default rootReducer;
