import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchListFailure, fetchListSuccess } from "../../actions/listActions/listActions";

import { listTypes } from "../../ActionTypes/listTypes";
import { API_BASE_ADDRESS } from "../../API";



var getList = (numb: any) => axios.get(`${API_BASE_ADDRESS}/get_list/` + numb);

function* fetchListSaga(numb: any) {
  try {
    // const response = yield call(getPosts);
    const response: AxiosResponse = yield call(getList, numb.numb);
    yield put(
      fetchListSuccess({
        list: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchListFailure({
        error: e.message
      })
    );
  }
}

function* ListSaga() {
  yield takeLatest(listTypes.FETCH_LIST_REQUEST, fetchListSaga);
}

export default ListSaga;
