import React, { useEffect } from "react";
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchRequest } from "../../redax/actions/searchActions/searchActions";
import { RootState } from "../../redax/reducers/rootReducer";
import './search.css';

function Search() {
  const history = useHistory();

  const dispatch = useDispatch();
  const { search, pending } = useSelector(
    (state: RootState) => state.search
  );

  useEffect(() => {
    let s = decodeURIComponent(window.location.search.substr(1).split("=")[1]);
    dispatch(fetchSearchRequest(s));
  }, [dispatch, window.location.search]);

  const loadDetail = (id: any) => {
    (document.getElementById('hair') as HTMLInputElement).classList.remove('active');
    (document.getElementById('beauty') as HTMLInputElement).classList.remove('active');
    (document.getElementById('nail') as HTMLInputElement).classList.remove('active');
    (document.getElementById('promotion') as HTMLInputElement).classList.remove('active');
    (document.getElementById('contact') as HTMLInputElement).classList.remove('active');
    (document.getElementById('search') as HTMLInputElement).value = '';
    (document.getElementById('searchM') as HTMLInputElement).value = '';
    history.push('/detail/' + id);
  }
  
  return (
    <div className='section container-fluid'>
      <h3 className="main-header-txt">ХАЙЛТЫН ҮР ДҮН</h3>
      <Row className='p-0'>
        <Col md={12} className='m-0 p-0 pb-3'>
          <Row className='p-0'>
            {search.map((list: any, ind: Number) => {
              return (
                <Col xl={4} sm={6} xs={12} className='p-0' key={list.id} onClick={() => loadDetail(list.id)}>
                  <Card className='p-md-3 image-card'>
                    <Card.Img variant="top" src={"./images/book/" + list.image} />
                    <Card.Body className='body-card'>
                      <Card.Title>{list.name}</Card.Title>
                      <Card.Text>
                        {list.short_desc}
                      </Card.Text>
                      {/* <Link to={`/detail/${list.id}`}>Дэлгэрэнгүй</Link> */}
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
            {search.length === 0 ? (
              <p>Үр дүн хоосон байна...</p>
            ) : (
              <></>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Search;