import { all } from '@redux-saga/core/effects';
import { fork } from 'redux-saga/effects';
import DetailSaga from './detailSaga/detailSaga';
import ListSaga from './listSaga/listSaga';
import postSaga from "./postSaga.ts/postSaga";
import PromotionDetailSaga from './promotionDetailSaga/promotionDetailSaga';
import PromotionSaga from "./promotionSaga/promotionSaga";
import searchSaga from './searchSaga/searchSaga.ts';
import rateSaga from './rateSaga/rateSaga.ts';

export function* rootSaga() {
  // yield all([fork(postsSaga)]);
  yield fork(postSaga);
  yield fork(ListSaga);
  yield fork(DetailSaga);
  yield fork(PromotionSaga);
  yield fork(PromotionDetailSaga);
  yield fork(searchSaga);
  yield fork(rateSaga);
}
