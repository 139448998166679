import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchRateFailure, fetchRateSuccess } from "../../actions/rateActions/rateActions";
import { rateTypes } from "../../ActionTypes/rateTypes";
import { API_BASE_ADDRESS } from "../../API";

const setRate = (worker: any) => 
  // axios.get(`${API_BASE_ADDRESS}/set_rate/` + worker.worker_id + '/' + worker.rate);
  axios.post(`${API_BASE_ADDRESS}/set_rate/`, {worker_id: worker.worker_id, rate: worker.rate});

function* fetchRateSaga(work: any){
  try {
    const response: AxiosResponse = yield call(setRate, work.work);
    yield put(
      fetchRateSuccess({
        rate: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchRateFailure({
        error: e.message
      })
    );
  }
}

function* rateSaga() {
  yield takeLatest(rateTypes.FETCH_RATE_REQUEST, fetchRateSaga);
}

export default rateSaga;
