import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchPromotionFailure, fetchPromotionSuccess } from "../../actions/promotionActions/promotionActions";

import { promotionTypes } from "../../ActionTypes/promotionTypes";
import { API_BASE_ADDRESS } from "../../API";



var getList = () => axios.get(`${API_BASE_ADDRESS}/get_promotion`);

function* fetchPromotionSaga() {
  try {
    const response: AxiosResponse = yield call(getList);
    yield put(
      fetchPromotionSuccess({
        list: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchPromotionFailure({
        error: e.message
      })
    );
  }
}

function* PromotionSaga() {
  yield takeLatest(promotionTypes.FETCH_PROMOTION_REQUEST, fetchPromotionSaga);
}

export default PromotionSaga;
