import { v4 as uuidV4 } from 'uuid';

// const ENV = (process.env.NODE_ENV as 'production' | 'test' | 'development') || 'development';
// const isDebug = ENV === 'development';
// const isClient = typeof window !== 'undefined';

// let API_ADDRESS = '';

// if (isClient && isDebug) {
//   API_ADDRESS = 'http://localhost:80/api';
// } else {
//   API_ADDRESS = '/api';
// }

// export const API_BASE_ADDRESS = "http://192.168.50.161:3000/api";
// export const API_BASE_ADDRESS = "http://localhost:3000/api";
export const API_BASE_ADDRESS = "https://salonapi.unisub-tech.com/api";

export const UUID_V4 = uuidV4();
