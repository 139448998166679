import { promotionDetailTypes } from "../../ActionTypes/promotionDetailTypes";
import {
  FetchPromotionDetailsFailure,
  FetchPromotionDetailsFailurePayload,
  FetchPromotionDetailsRequest,
  FetchPromotionDetailsSuccess,
  FetchPromotionDetailsSuccessPayload,
} from "../../types/type";

export const fetchPromotionDetailRequest = (id:any): FetchPromotionDetailsRequest => ({
  type: promotionDetailTypes.FETCH_PROMOTION_DETAIL_REQUEST,
  id,
});

export const fetchPromotionDetailSuccess = (
  payload: FetchPromotionDetailsSuccessPayload,
): FetchPromotionDetailsSuccess => ({
  type: promotionDetailTypes.FETCH_PROMOTION_DETAIL_SUCCESS,
  payload
});

export const fetchPromotionDetailFailure = (
  payload: FetchPromotionDetailsFailurePayload
): FetchPromotionDetailsFailure => ({
  type: promotionDetailTypes.FETCH_PROMOTION_DETAIL_FAILURE,
  payload
});
