import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchPostsFailure,
  fetchPostsSuccess
} from "../../actions/postsActions/postsActions";
import { postTypes } from "../../ActionTypes/postTypes";
import { API_BASE_ADDRESS } from "../../API";


const getPosts = () =>
  axios.get(`${API_BASE_ADDRESS}/get_main`);

function* fetchPostsSaga() {
  try {
    // const response = yield call(getPosts);
    const response: AxiosResponse = yield call(getPosts);
    yield put(
      fetchPostsSuccess({
        posts: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchPostsFailure({
        error: e.message
      })
    );
  }
}

function* postsSaga() {
  yield takeLatest(postTypes.FETCH_POST_REQUEST, fetchPostsSaga);
}

export default postsSaga;
