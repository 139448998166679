import React from 'react';
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Switch, useParams } from "react-router-dom";
import './index.css';
import App from './App';
// import Users from "./users";
// import Notfound from "./notfound";
import Header from "./pages/header/header";
import Footer from "./pages/footer/footer";
import reportWebVitals from './reportWebVitals';
import Hair from './pages/Hair/hair';
import Detail from './pages/detail/detail';
import { Provider } from "react-redux";
import News from './pages/news/news';
import NewsRead from './pages/news/newsread';
import Contact from './pages/contact/contact';
import Page404 from './Page404';
import store from "./redax/store";
import Search from './pages/search/search';
// import { render } from 'react-dom';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Router>
      <Provider store={store}>
      <div>
          <Header/>
          <Switch>
            <Route exact path="/" component={App} />
            <Route exact path="/hair" component={Hair} />
            <Route exact path="/beauty" component={Hair} />
            <Route exact path="/nail" component={Hair} />
            <Route exact path="/promotion" component={News} />
            <Route exact path="/promotion/read/:id" component={NewsRead} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/detail/:id"  component={Detail} />
            <Route exact path="**" component={Page404} />
          </Switch>
          <Footer/>
      </div>
      </Provider>
    </Router>
);

// class Index extends React.Component {
//   render() {
//     return (
//       <Router>
//         <div>
//           <Header/>
//           <Switch>
//             <Route exact path="/" component={App} />
//             {/* <Route exact path="/users" component={Users} /> */}
//           </Switch>
//           <Footer/>
//         </div>
//       </Router>
//     );
//   }
// }

// render(<Index />, document.getElementById('root'));
reportWebVitals();
