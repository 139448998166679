import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchDetailFailure, fetchDetailSuccess } from "../../actions/detailActions/detailActions";
import { detailTypes } from "../../ActionTypes/detailTypes";
import { API_BASE_ADDRESS } from "../../API";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

var getDetail = (numb: any) => axios.get(`${API_BASE_ADDRESS}/get_detail/` + numb); 

function* fetchDetailSaga(numb: any) {
  try {
    // const response = yield call(getPosts);
    const response: AxiosResponse = yield call(getDetail,numb.numb);
    yield put(
      fetchDetailSuccess({
        detail: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchDetailFailure({
        error: e.message
      })
    );
  }
}

function* DetailSaga() {
  yield takeLatest(detailTypes.FETCH_DETAIL_REQUEST, fetchDetailSaga);
}

export default DetailSaga;
