import { rateTypes } from "../../ActionTypes/rateTypes";
import { RateActions, RateState } from "../../types/type";

const initialState: RateState = {
  pendingRate: false,
  rate: '',
  error: null
};

export default (state = initialState, action: RateActions) => {
  switch (action.type) {
    case rateTypes.FETCH_RATE_REQUEST:
      return {
        ...state,
        pendingRate: false
      };
    case rateTypes.FETCH_RATE_SUCCESS:
      return {
        ...state,
        pendingRate: true,
        rate: action.payload.rate,
        error: null
      };
    case rateTypes.FETCH_RATE_FAILURE:
      return {
        ...state,
        pendingRate: false,
        rate: '',
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
