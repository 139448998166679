import { promotionTypes } from "../../ActionTypes/promotionTypes";
import {
  FetchPromotionFailure,
  FetchPromotionFailurePayload,
  FetchPromotionRequest,
  FetchPromotionSuccess,
  FetchPromotionSuccessPayload
} from "../../types/type";

export const fetchPromotionRequest = (): FetchPromotionRequest => ({
  type: promotionTypes.FETCH_PROMOTION_REQUEST
});

export const fetchPromotionSuccess = (
  payload: FetchPromotionSuccessPayload,
): FetchPromotionSuccess => ({
  type: promotionTypes.FETCH_PROMOTION_SUCCESS,
  payload
});

export const fetchPromotionFailure = (
  payload: FetchPromotionFailurePayload
): FetchPromotionFailure => ({
  type: promotionTypes.FETCH_PROMOTION_FAILURE,
  payload
});
