/* eslint-disable no-loop-func */
import { Col, ListGroup, Row, Tab } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import './hair.css';
import GoogleMapReact from "google-map-react";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redax/reducers/rootReducer';
import { fetchListRequest } from '../../redax/actions/listActions/listActions';
import { isMobile } from 'react-device-detect';

let mapOrig: any, mapsOrig: any;
let latPrev: any, lonPrev: any, infoWindowPrev: any, markerPrev: any[] = [], markerPrevOne: any, mPrev: any, nPrev: any;
let markersArray: any[] = [];

function Hair() {
  const [s, setSearch] = useState('');
  const [loaded, setLoad] = useState(false);
  const [listPrev, setPrevList] = useState([]);
  const defaultProps = {
    center: {
      lat: 47.9188,
      lng: 106.9176
    },
    zoom: 14
  };

  const dispatch = useDispatch();
  const { pending, list } = useSelector(
    (state: RootState) => state.list
  );
  let numb: any;

  if (window.location.href.search("hair") !== -1 ) {
    numb = 1;
  } else if (window.location.href.search("beauty") !== -1 ) {
    numb = 2;
  } else if (window.location.href.search("nail") !== -1 ){
    numb = 3;
  }
  if (infoWindowPrev != null) {
    infoWindowPrev.close();
  }
  if (markerPrev.length > 0) {
    for(let i = 0; i < markerPrev.length; i++) {
      markerPrev[i].setMap(null);
    }
    markerPrev = [];
  }

  useEffect(() => {
    dispatch(fetchListRequest(numb));
    setPrevList(list);
    // loadMarkers();
  }, [dispatch, numb]);

  const renderMarkers = (map: any, maps: any) => {
    mapOrig = map;
    mapsOrig = maps;
    
    for(var i = 0; i < list.length; i++){
     var location = list[i].location;
     for(var j = 0; j < list[i].salbars.length; j++) {
        location = list[i].salbars[j].lat + ',' + list[i].salbars[j].lng;
        var lat1 = Number(location.split(",")[0]);
        var lng1 = Number(location.split(",")[1]);

        let marker = new mapsOrig.Marker({
          position: {
            lat: lat1,
            lng: lng1
          },
          map: mapOrig,
          title: list[i].name,
          icon: 'images/blue-marker.png',
        });        
        let infoWindow = new mapsOrig.InfoWindow({
          content: '<div class="imageMap"><img src="../images/book/'+ list[i].salbars[j].image +'"  alt=""></img><p>' + list[i].salbars[j].name + '</p><p>' + list[i].salbars[j].address + '</p><a href="/detail/'+ list[i].id +'">Дэлгэрэнгүй...</a> </div>'
        });
        marker.addListener('click', function() {
          if (infoWindowPrev != null) {
            infoWindowPrev.close();
          }
          mapOrig.panTo(marker.getPosition());
          infoWindow.open(mapOrig, marker);
        });
        markersArray.push(marker);
      }
      if(list[i].salbars.length === 0) {
        lat1 = Number(location.split(",")[0]);
        lng1 = Number(location.split(",")[1]);

        let marker = new mapsOrig.Marker({
          position: {
            lat: lat1,
            lng: lng1
          },
          map: mapOrig,
          title: list[i].name,
          icon: 'images/blue-marker.png',
        });
        let infoWindow = new mapsOrig.InfoWindow({
          content: '<div class="imageMap"><img src="../images/book/'+ list[i].image +'"  alt=""></img><p>' + list[i].name + '</p><p>' + list[i].address + '</p><a href="/detail/'+ list[i].id +'">Дэлгэрэнгүй...</a> </div>'
        });
        marker.addListener('click', function() {
          if (infoWindowPrev != null) {
            infoWindowPrev.close();
          }
          mapOrig.panTo(marker.getPosition());
          infoWindow.open(mapOrig, marker);
        });
        markersArray.push(marker);
      }
    }
    mapOrig.setMapTypeId('satellite');
    setLoad(true);
  }

  if (pending) {
    loadMarkers();
  }

  function loadMarkers() {
    if (loaded) {
      for(var i = 0; i < markersArray.length; i++){
        markersArray[i].setMap(null);
      }
      markersArray = [];
      for(i = 0; i < list.length; i++){
        var location = list[i].location;
        for(var j = 0; j < list[i].salbars.length; j++) {
          location = list[i].salbars[j].lat + ',' + list[i].salbars[j].lng;
          var lat1 = Number(location.split(",")[0]);
          var lng1 = Number(location.split(",")[1]);
      
          let marker = new mapsOrig.Marker({
            position: {
              lat: lat1,
              lng: lng1
            },
            map: mapOrig,
            title: list[i].name,
            icon: 'images/blue-marker.png',
          });
          let infoWindow = new mapsOrig.InfoWindow({
            content: '<div class="imageMap"><img src="../images/book/'+ list[i].salbars[j].image +'"  alt=""></img><p>' + list[i].salbars[j].name + '</p><p>' + list[i].salbars[j].address + '</p><br/><a href="/detail/'+ list[i].id +'">Дэлгэрэнгүй...</a> </div>'
          });
          // src={"images/workers/" + workList.image}
          marker.addListener('click', function() {
            if (infoWindowPrev != null) {
              infoWindowPrev.close();
            }
            infoWindowPrev = infoWindow;
            markerPrevOne = marker;          
            mapOrig.panTo(marker.getPosition());
            infoWindow.open(mapOrig, marker);
          });
          markersArray.push(marker);
        }
        if(list[i].salbars.length === 0) {
          lat1 = Number(location.split(",")[0]);
          lng1 = Number(location.split(",")[1]);
  
          let marker = new mapsOrig.Marker({
            position: {
              lat: lat1,
              lng: lng1
            },
            map: mapOrig,
            title: list[i].name,
            icon: 'images/blue-marker.png',
          });
          let infoWindow = new mapsOrig.InfoWindow({
            content: '<div class="imageMap"><img src="../images/book/'+ list[i].image +'"  alt=""></img><p>' + list[i].name + '</p><p>' + list[i].address + '</p><br/><a href="/detail/'+ list[i].id +'">Дэлгэрэнгүй...</a> </div>'
          });
          marker.addListener('click', function() {
            if (infoWindowPrev != null) {
              infoWindowPrev.close();
            }
            infoWindowPrev = infoWindow;
            markerPrevOne = marker;          
            mapOrig.panTo(marker.getPosition());
            infoWindow.open(mapOrig, marker);
          });
          markersArray.push(marker);
        }
      }
    }
  }

  const changeMarker = (lat: any, lon: any, m: any, n: any) => {
    let ch = false;
    if (latPrev != null) {
      let marker2 = new mapsOrig.Marker({
        position: {
          lat: latPrev,
          lng: lonPrev
        },
        map: mapOrig,
        title: markerPrevOne.title,
        icon: 'images/blue-marker.png',
      });
      markerPrev.push(marker2);
      let infoWindowPrev2: any;
      if(nPrev > -1) {
        infoWindowPrev2 = new mapsOrig.InfoWindow({
          content: '<div class="imageMap"><img sandbox="allow-modal" src="../images/book/'+ list[mPrev].salbars[nPrev].image +'"  alt=""></img><p>' + list[mPrev].salbars[nPrev].name + '</p><p>' + list[mPrev].salbars[nPrev].address + '</p><br/><a href="/detail/'+ list[mPrev].id +'">Дэлгэрэнгүй...</a> </div>'
        });
      } else {
        infoWindowPrev2 = new mapsOrig.InfoWindow({
          content: '<div class="imageMap"><img sandbox="allow-modal" src="../images/book/'+ list[mPrev].image +'"  alt=""></img><p>' + list[mPrev].name + '</p><p>' + list[mPrev].address + '</p><br/><a href="/detail/'+ list[mPrev].id +'">Дэлгэрэнгүй...</a> </div>'
        });
      }
      marker2.addListener('click', function() {
        if (infoWindowPrev != null) {
          infoWindowPrev.close();
        }
        markerPrevOne = marker2;
        infoWindowPrev = infoWindowPrev2;
        mapOrig.panTo(marker2.getPosition());
        infoWindowPrev2.open(mapOrig, marker2);
      });
    }
    for(var i = 0; i < list.length; i++) {
      if (infoWindowPrev != null) {
        infoWindowPrev.close();
      }
      for(let j = 0; j < list[i].salbars.length; j++) {
        var location = list[i].salbars[j].lat + ',' + list[i].salbars[j].lng;        
        if(Number(location.split(",")[0]) === lat && Number(location.split(",")[1]) === lon) {
          latPrev = lat;
          lonPrev = lon;
          mPrev = m;
          nPrev = n;
          let marker = new mapsOrig.Marker({
            position: {
              lat: lat,
              lng: lon
            },
            map: mapOrig,
            title: list[i].name,
            icon: 'images/orange-marker.png',
          });
          markerPrev.push(marker);
          markerPrevOne = marker;
          infoWindowPrev = new mapsOrig.InfoWindow({
            content: '<div class="imageMap"><img src="../images/book/'+ list[i].salbars[j].image +'"  alt=""></img><p>' + list[i].salbars[j].name + '</p><p>' + list[i].salbars[j].address + '</p><br/><a href="/detail/'+ list[i].id +'">Дэлгэрэнгүй...</a> </div>'
          });
          mapOrig.panTo(marker.getPosition());
          infoWindowPrev.open(mapOrig, marker);
          ch = true;
          marker.addListener('click', function() {
            if (infoWindowPrev != null) {
              infoWindowPrev.close();
            }
            markerPrevOne = marker;
            mapOrig.panTo(marker.getPosition());
            infoWindowPrev.open(mapOrig, marker);
          });
          break;
        }
      }
      if(ch) {
        break;
      }
      if(list[i].salbars.length === 0) {
        location = list[i].location;
        if(Number(location.split(",")[0]) === lat && Number(location.split(",")[1]) === lon) {
          latPrev = lat;
          lonPrev = lon;
          mPrev = m;
          nPrev = n;
          let marker = new mapsOrig.Marker({
            position: {
              lat: lat,
              lng: lon
            },
            map: mapOrig,
            title: list[i].name,
            icon: 'images/orange-marker.png',
          });
          markerPrev.push(marker);
          markerPrevOne = marker;
          infoWindowPrev = new mapsOrig.InfoWindow({
            content: '<div class="imageMap"><img src="../images/book/'+ list[i].image +'"  alt=""></img><p>' + list[i].name + '</p><p>' + list[i].address + '</p><br/><a href="/detail/'+ list[i].id +'">Дэлгэрэнгүй...</a> </div>'
          });
          mapOrig.panTo(marker.getPosition());
          infoWindowPrev.open(mapOrig, marker);
          marker.addListener('click', function() {
            if (infoWindowPrev != null) {
              infoWindowPrev.close();
            }
            markerPrevOne = marker;
            mapOrig.panTo(marker.getPosition());
            infoWindowPrev.open(mapOrig, marker);
          });
          break;
        }
      }
    }
    if (isMobile) {
      window.scrollTo(0, document.body.scrollHeight - 150);
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }

  const search = () => {
    let filter, ul, li, a, i, txtValue;
    filter = s.toUpperCase();
    ul = document.getElementsByClassName("accordion")[0];
    li = ul.getElementsByClassName("accordion-item");
    for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("button")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].setAttribute('style', '');
        } else {
            li[i].setAttribute('style', 'display: none;');
        }
    }
  }

  return (
      <Row className='container-fluid pt-3'>
        <input type="text" placeholder="Хайлт..." className="form-control col-lg-12" id="search" onKeyUp={() => search()} value={s} onChange={handleChange} />
        <Col xs={12} sm={4} className="scrolled-aria">
        {/* <h3>Жагсаалт</h3> */}

        {list && list !== undefined ? (
          <Accordion>
                {list.map((list: any, ind: Number) => {
                    return (
                      list.salbars.length > 0 ? (
                        <Accordion.Item eventKey={list.id}  key={list.id}>
                          <Accordion.Header id = {list.id}>{list.name}</Accordion.Header>
                          <Accordion.Body>
                            {list.salbars.map((salbar: any, index: Number) => {
                              return <div className='dflex' key={`salbar-${salbar.id}`}>
                                <p className='salbar' onClick = {() => changeMarker(Number(salbar.lat),Number(salbar.lng), ind, index)}>{salbar.name}</p> <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : (
                      <Accordion.Item eventKey={list.id}  key={list.id}>
                        <Accordion.Header  className="one-item" id = {list.id} onClick = {() => changeMarker(Number(list.location.split(",")[0]),Number(list.location.split(",")[1]), ind, -1)}>{list.name}</Accordion.Header>
                      </Accordion.Item>
                    ));
                  })}
          </Accordion>
          ) : (
            <></>
          )}
          {/* <Accordion.Item eventKey="1">
            <Accordion.Header className="one-item" onClick = {() => changeMarker(47.9188, 106.9176)}>Үсчин2</Accordion.Header>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Үсчин3</Accordion.Header>
            <Accordion.Body>
              <div className='dflex'>
                <p className='salbar'>Салбар1</p> <FontAwesomeIcon icon={faChevronRight} />
              </div>
              <div className='dflex'>
                <p className='salbar'>Салбар2</p> <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Үсчин4</Accordion.Header>
            <Accordion.Body>
            <div className='dflex'>
                <p className='salbar'>Салбар1</p> <FontAwesomeIcon icon={faChevronRight} />
              </div>
              <div className='dflex'>
                <p className='salbar'>Салбар2</p> <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Accordion.Body>
          </Accordion.Item> */}
        </Col>
        <Col xs={12} sm={8} className="map-aria">
          <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyANrSZmh9Dzui1tXkC9H6R72Jv_03JxGcE" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
            >
            </GoogleMapReact>
          </div>
        </Col>
      </Row>
  );
}

export default Hair;