import React from 'react';

function Page404() {
  
  return (
    <>
      <h3 className='error-page'>404 error</h3>
    </>
  );
}

export default Page404;