import React, { useEffect } from "react";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { Col, Row } from 'react-bootstrap';
import './news.css';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchPromotionDetailRequest } from "../../redax/actions/promotionDetailActions/promotionDetailActions";

function NewsRead() {
  window.scroll(0, 0);
  let id: any;
  id  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
  
  const dispatch = useDispatch();
  const { promotionDetail,pending } = useSelector(
    (state: RootState) => state.promotionDetail
  );

  useEffect(() => {
    dispatch(fetchPromotionDetailRequest(id));
  }, [dispatch,id]);
  
  return (
    <div className='section container-fluid'>
    {pending ? (
      <>
        {/* <h3 className="main-header-txt">ДЭЛГЭРЭНГҮЙ</h3> */}
        <Row className='mt p-0'>
          <Col md={9} className='m-0 p-0 pb-3'>
            <CardGroup className='container-fluid'>
              <Card  className='p-md-12 p-0'>
                <Card.Img variant="top" src={"/images/" + promotionDetail.image} />
                <Card.Body>
                  <Card.Title>{promotionDetail.name}</Card.Title>
                  <Card.Text> 
                    <span className="expire-date-pro">
                      Дуусах хугацаа: {
                          new Date(promotionDetail.n_date).getUTCFullYear() + "-" +
                          ("00" + (new Date(promotionDetail.n_date).getUTCMonth() + 1)).slice(-2) + "-" +
                          ("00" + new Date(promotionDetail.n_date).getUTCDate()).slice(-2) + " " +
                          ("00" + new Date(promotionDetail.n_date).getUTCHours()).slice(-2) + ":" +
                          ("00" + new Date(promotionDetail.n_date).getUTCMinutes()).slice(-2) + ":" +
                          ("00" + new Date(promotionDetail.n_date).getUTCSeconds()).slice(-2)}
                    </span>
                  </Card.Text>
                  <br/>
                  {promotionDetail.descr}
                </Card.Body>
              </Card>
            </CardGroup>
          </Col>
          <Col md={3} className='m-0 p-0 pb-3 fb-padd'>
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmglpages%2F&tabs=timeline&width=330&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=361420201045179" width="100%" height="800" scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title="FB"></iframe>
          </Col>
        </Row>
      </>
    ) : (
      <></>
    )}
    </div>
  );
}

export default NewsRead;