import { listTypes } from "../../ActionTypes/listTypes";
import { ListState , ListActions } from "../../types/type";

const initialState: ListState = {
  pending: false,
  list: [],
  error: null,
};

export default (state = initialState, action: ListActions,) => {
  switch (action.type) {
    case listTypes.FETCH_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
    case listTypes.FETCH_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        list: action.payload.list,
        error: null
      };
    case listTypes.FETCH_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        list: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
