import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contact.css';

function Contact() {

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [desc, setDesc] = useState("");

  const checkMail = (email: string) => {
    const re =
      /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const checkPhone = (phone: string) => {
    const re = /^[0-9]{8}$/;
    return re.test(phone);
  };

  const send = () => {
    if(firstName === null || firstName === '') {
      return;
    }
    if(email === null || email === '') {
      return;
    } else if(!checkMail(email)) {
      return;
    }
    if(phone === null || phone === '') {
      return;
    } else if(!checkPhone(phone)) {
      return;
    }
    if(desc === null || desc === '') {
      return;
    }
    // toast("АМЖИЛТТАЙ ИЛГЭЭЛЭЭ.");
    toast.success("АМЖИЛТТАЙ ИЛГЭЭЛЭЭ.", {
      onClose: () => {
        setFirstName('');
        setEmail('');
        setPhone('');
        setDesc('');
      },
      autoClose: 3000
    });
  }

  return (
    <div className='section container-fluid'>
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe className="gmap_iframe" scrolling="no" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Амбассадор Оффис by Ochir Estate, Peace Ave 15a5 Sukhbaatar district-1r khoroo Ulaanbaatar, 14210&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"  width="100%" height="450" title="map"></iframe>
          </div>
        </div>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
          <form className="sanal" onSubmit={e => e.preventDefault()}>
            <label htmlFor="firstName">Овог, Нэр</label>
            <input
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder="Овог, Нэр"
              type="text"
              name="firstName"
              maxLength={60}
              required
            />
            <label htmlFor="email">Имэйл</label>
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Имэйл"
              type="email"
              name="email"
              maxLength={60}
              required
            />
            <label htmlFor="phone">Утас</label>
            <input
              value={phone}
              onChange={e => setPhone(e.target.value)}
              placeholder="Утас"
              type="tel"
              name="phone"
              pattern="[0-9]{8}"
              maxLength={8}
              required
            />
            <label htmlFor="desc">Тайлбар</label>
            <textarea placeholder="Санал хүсэлт..." name="desc" required
              value={desc}
              onChange={e => setDesc(e.target.value)}>
            </textarea>
            <button type="submit" onClick={() => send()}>Илгээх</button>
          </form>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className="address">
              <div>
                <p>
                  Утас: 8906-3443 
                </p>
                <p>
                  E-mail: contact@unisub-tech.com
                </p>
                <p>
                  Хаяг: Амбассадор оффис 403 тоот, Энхтайваны өргөн чөлөө - 15A/5 Сүхбаатар дүүрэг, 1-р хороо, Улаанбаатар хот, Монгол Улс
                </p>
                <img src="../../images/office.jpg" alt="" />
              </div>
            </div>
          </Col>
        </Row>
        <ToastContainer position="bottom-right" />
    </div>
  );
}

export default Contact;