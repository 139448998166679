import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchPromotionDetailFailure, fetchPromotionDetailSuccess } from "../../actions/promotionDetailActions/promotionDetailActions";
import { promotionDetailTypes } from "../../ActionTypes/promotionDetailTypes";
import { API_BASE_ADDRESS } from "../../API";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

var getPromotionDetail = (id: any) => axios.get(`${API_BASE_ADDRESS}/get_promotion/` + id);

function* fetchPromotionDetailSaga(id: any) {
  try {
    // const response = yield call(getPosts);
    const response: AxiosResponse = yield call(getPromotionDetail,id.id);
    yield put(
      fetchPromotionDetailSuccess({
        promotionDetail: response.data        
      })
    );
  } catch (e:any) {
    yield put(
      fetchPromotionDetailFailure({
        error: e.message
      })
    );
  }
}

function* PromotionDetailSaga() {
  yield takeLatest(promotionDetailTypes.FETCH_PROMOTION_DETAIL_REQUEST, fetchPromotionDetailSaga);
}

export default PromotionDetailSaga;
