import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// @ts-ignore
import Slider from './Slider';
import { useDispatch, useSelector } from "react-redux";
import { fetchPostsRequest } from "./redax/actions/postsActions/postsActions";
import { RootState } from "./redax/reducers/rootReducer";
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';


function App() {
  const history = useHistory();
  const [first, setFirst] = React.useState(true);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const responsive1 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const dispatch = useDispatch();
  const { posts } = useSelector(
    (state: RootState) => state.posts
  );

  var worksList :any [];
  var services :any [];
  var banner : any [];
  var corps :any [];


  worksList = posts['workers'];
  services = posts['services'];
  banner = posts['banners'];
  corps = posts['corps'];

  useEffect(() => {
    dispatch(fetchPostsRequest());
    /*if(first) {
      dispatch(fetchPostsRequest());
      setFirst(false);
    } else {
      setInterval(() => {
        dispatch(fetchPostsRequest());
      }, 60000*10);
    }*/
    window.scroll(0, 0);
  }, [dispatch]);

  const loadService = (id: any) => {
    let path = `detail/` + id + '?type=service';
    history.push(path);
  };

  const loadWorker = (id: any) => {
    let path = `detail/` + id + '?type=worker';
    history.push(path);
  };

  return (
    <div className='section container-fluid2'>
      <Slider data={banner} />
      <div className='sec'>
        <h3 className="main-header-txt">ОНЦГОЙ ҮЙЛЧИЛГЭЭ</h3>
        <Row className='p-0 m-0'>
          {services && services !== undefined ? (
              <>
                {services.map((services: any) => {
                    return (
                    <Col xl={3} lg={4} sm={6} xs={12} className=' p-0 mt-3' key={services.id}>
                      <Card className='p-md-3 m-1 image-card' onClick={() => loadService(services.book_id)}>
                        <Card.Img variant="top" src={"images/services/" + services.image} alt={services.name} />
                        <Card.Body className='body-card'>
                          <div>
                            <Card.Title>{services.name}</Card.Title>
                            <Card.Text>
                              {services.descr}
                            </Card.Text>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    );
                  })}
              </>
            ) : (
              <></>
            )}
        </Row>
      </div>
      <div className='sec'>
        <h3 className="main-header-txt pb-3">ШИЛДГҮҮД</h3>
            {worksList && worksList !== undefined ? (
              <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={1500} infinite={true}
              itemClass="carousel-item-padding" className="pb-3 workers">
                {worksList.map((workList: any) => {
                    return (
                      <div key={workList.id} onClick={() => loadWorker(workList.book_id)}>
                        <img
                          id={workList.id}
                          className="d-block w-100"
                          src={"images/workers/" + workList.image}
                          alt="Third slide"
                        />
                        <p className='name'>{workList.name}</p>
                        <p className='work-job'>{workList.short_descr}</p>
                      </div>
                    );
                  })}
            </Carousel>
            ) : (
              <></>
            )}
      </div>
      <div className='main'>
      <h3 className="main-header-txt pb-3">ХАМТРАГЧ БАЙГУУЛЛАГУУД</h3>
      {corps && corps !== undefined ? (
              <Carousel responsive={responsive1} autoPlay={true} autoPlaySpeed={1500} infinite={true}
              itemClass="carousel-item-padding" className="company-logo">
                {corps.map((corp: any) => {
                    return (
                      <a href={corp.url} target="_blank" key={corp.id} rel="noreferrer">
                        <div className='corps'>
                          <img src={"images/corps/" + corp.image} className="d-block" alt={corp.name} title={corp.name} />
                        </div>
                      </a>
                    );
                  })}
            </Carousel>
            ) : (
              <></>
            )}
      </div>
    </div>
  );
}

export default App;
