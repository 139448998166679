import React, { useEffect, useRef, useState } from 'react';
import './header.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Link , useHistory } from 'react-router-dom';

function MyVerticallyCenteredModal(props:any) {  

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="Auth-form-container"
    >
      <form className="Auth-form">
        <div className="Auth-form-content">

          <h3 className="Auth-form-title">Нэвтрэх хэсэг</h3>
          <div className="form-group mt-3">
            <label>Нэвтрэх нэр</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Имэйл хаяг"
            />
          </div>
          <div className="form-group mt-3">
            <label>Нууц үг</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Нууц үг"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Нэвтрэх
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            Нууц үгээ <a href="#">мартсан?</a>
          </p>
          <div className="d-grid gap-2 mt-3">
            <button type="button" className="btn btn-primary">
              Бүртгүүлэх
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

function Header() {
  const [hamburg, setTurnOff] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [searchMobile, setMSearch] = React.useState("");

  const history = useHistory();
  
  const routeChange = () => {
    if((document.getElementById('search') as HTMLInputElement).value !== ""){
      let path = `/search?s=` + (document.getElementById('search') as HTMLInputElement).value;
      history.push(path);
    }
  }

  const routeChange2 = () =>{ 
    if((document.getElementById('searchM') as HTMLInputElement).value !== ""){
      let path = `/search?s=` + (document.getElementById('searchM') as HTMLInputElement).value;
      history.push(path);
    }
  }
  const concernedElement:any = document.querySelector(".menu-items");
  document.addEventListener("mousedown", (e: any) => {
    if (e.target !== null) {
      if (concernedElement !== null && !concernedElement.contains(e.target) && e.target.name !== 'hamburg') {
        setTurnOff(false);
      }
    }
  });
  const handleChange = () => {
    if (hamburg) {
      setTurnOff(false);
    } else {
      setTurnOff(true);
    }
  }

  const handleKeyDown = (event:any) => {    
    setSearch((document.getElementById('search') as HTMLInputElement).value);
  };

  const handleKeyDown2 = (event:any) => {
    setMSearch((document.getElementById('search') as HTMLInputElement).value);
  };

  const turnOff = (type: any) => {
    window.scroll(0, 0);
    setTurnOff(false);
    (document.getElementById('search') as HTMLInputElement).value = '';
    (document.getElementById('searchM') as HTMLInputElement).value = '';
    setSearch("");
    setMSearch("");
    removeActive();
    (document.getElementById(type) as HTMLInputElement).classList.add('active');
  }

  const removeActive = () => {
    (document.getElementById('hair') as HTMLInputElement).classList.remove('active');
    (document.getElementById('beauty') as HTMLInputElement).classList.remove('active');
    (document.getElementById('nail') as HTMLInputElement).classList.remove('active');
    (document.getElementById('promotion') as HTMLInputElement).classList.remove('active');
    (document.getElementById('contact') as HTMLInputElement).classList.remove('active');
    (document.getElementById('search') as HTMLInputElement).value = '';
    (document.getElementById('searchM') as HTMLInputElement).value = '';
    setSearch("");
    setMSearch("");
  }

  return (
    <header>
      <nav className="navbar">
          <div className="navbar-container cont1">

              <input type="checkbox" name="hamburg" id="hamburg" checked={hamburg} onChange={handleChange}></input>
              <div className="hamburger-lines">
                  <span className="line line1"></span>
                  <span className="line line2"></span>
                  <span className="line line3"></span>
              </div>
              <ul className="menu-items">
                  <li><Link to="/hair" id="hair" onClick={() => turnOff('hair')}>Үсчин</Link></li>
                  <li><Link to="/beauty" id="beauty" onClick={() => turnOff('beauty')}>Гоо сайхан</Link></li>
                  <li><Link to="/nail" id="nail" onClick={() => turnOff('nail')}>Маникюр, Педикюр</Link></li>
                  <li><Link to="/promotion" id="promotion" onClick={() => turnOff('promotion')}>Урамшуулал</Link></li>
                  <li><Link to="/contact" id="contact" onClick={() => turnOff('contact')}>Холбоо барих</Link></li>
                  <li>
                  <Form className="d-flex search2" onSubmit={e => e.preventDefault()}>
                    <Form.Control
                      type="search"
                      placeholder="Хайлт..."
                      className="me-2"
                      aria-label="Search"
                      defaultValue={searchMobile}
                      id="searchM"
                      onInput={handleKeyDown2} onChange={routeChange2}
                    />
                  </Form>
                </li>
              </ul>
              <Link to="/" onClick={removeActive} className="logo"><img src="/logo192.png" alt="logo" width="50" /></Link>
              <div className='cont'>
                <div className="search-box">
                  <button className="btn-search"><FontAwesomeIcon icon={faSearch}  /></button>
                  <input id = "search" type="text" onInput={handleKeyDown} onChange={routeChange} className="input-search" placeholder="Хайлт..."></input>
                </div>
                <div className='login'  onClick={() => setModalShow(true)}>
                  <FontAwesomeIcon icon={faUser} title="Нэвтрэх" />
                </div>
              </div>
          </div>
      </nav>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </header>
  );
}

export default Header;
