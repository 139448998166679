import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSearchFailure, fetchSearchSuccess } from "../../actions/searchActions/searchActions";
import { searchTypes } from "../../ActionTypes/searchTypes";
import { API_BASE_ADDRESS } from "../../API";

const getSearch = (search: any) => 
  // axios.post(`${API_BASE_ADDRESS}/get_search?search=${text}`);
  axios.post(`${API_BASE_ADDRESS}/get_search/`, {search: search});


function* fetchSearchSaga(search: any){
  try {
    const response: AxiosResponse = yield call(getSearch, search.search);
    yield put(
      fetchSearchSuccess({
        search: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchSearchFailure({
        error: e.message
      })
    );
  }
}

function* searchSaga() {
  yield takeLatest(searchTypes.FETCH_SEARCH_REQUEST, fetchSearchSaga);
}

export default searchSaga;
